//ブレークポイント
$sp_max_width: 1024px;
$pc_min_width: 1025px;

$sp_max_width_login: 570px;
$sp_max_width_info: 680px;
$sp_max_width_map: 700px;
$sp_max_width_min: 350px;
$sp_max_width_bestprice: 750px;

//色
$color_black: #333;
$color_black02: #4A4A4A;
$color_yellow: #BF9D2E;
$color_yellow02: #C9B055;
$color_yellow03: #AD9749;
$color_yellow_lt: #F3EDD9;
$color_gray01: #9B9B9B;
$color_gray02: #F9F8F6;
$color_gray03: #F4F2ED;
$color_gray04: #CBC8C2;
$color_gray05: #DBDBDB;
$color_gray06: #FCFCFC;
$color_brown: #640205;
$color_red: #A72A2E;
$color_red_lt: #C95555;
$color_blue: #004098;
$color_blue_lt: #5593C9;

//フォント
$base_font_family: "メイリオ", Meiryo,  "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "游ゴシック", "Yu Gothic", YuGothic,"ＭＳ ゴシック", Verdana, "Droid Sans", sans-serif;
$font_yumin: "游明朝", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "Sawarabi Mincho", serif, "Droid Sans";

//opacity
$opacity: 0.8;

@import "_reset";
@import "_iconfont";
@import "iconfont/_icons";
@import "_base";
@import "_common"; //汎用class
@import "_top";  //TOPページ
@import "_login";  //ログイン画面
@import "_info";  //お知らせ詳細
@import "_map";  //MAP画面
@import "_bestprice";  //MAP画面
@import "_ie11"; //IE11用CSS


.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.mt30 {
	margin-top:30px;
}

#post_title {
    margin: 0;
    padding: 15px 22px;
    font-size: 26px;
    font-weight: bold;
    color: #fff;
    background: #CFCC61;
}

.body-wrap {
    margin: 30px 15px;
    h3 {
		color: #6A070D;
    	font-weight: bold;
    	font-size: 20px;
    	margin: 0;
    	padding: 0;
    }
}


.pb_pricemenu_title {
    font-size: 16px;
    border-left: 5px solid #CFCC61;
    border-bottom: 1px solid #CFCC61;
    padding: 5px 0 5px 20px;
    font-weight: bold;
    color: #CFCC61;
}

.madori {
	text-align:center;
	margin:30px 0;
	img {
		width: 90%;
	}
}

.gallery-photo {
	margin:30px 0;
}

.baseinfo {
	margin:30px 0;
	tr.baseinfo-title {
		background:#DBD989;
		font-weight:bold;
		td {
			font-weight:bold;
			font-size:16px;
		}
	}
}

.access-map {
	text-align:center;
	img {
		border:1px solid #ccc;
		width:90%;
	}
}