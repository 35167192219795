/* login
----------------------------------------------------------*/
.login-area {
	padding: 0 0 60px;
	background-image: url(../img/bg-login.jpg);
}
.login-area-head {
	overflow: hidden;
	position: relative;
	height: 110px;
	background-image: url(../img/bg-login-ttl.jpg);
	background-size: contain;
	background-repeat: repeat;
	&:before,
	&:after {
		content: "";
		position: absolute;
		top: 5px;
		left: 0;
		right: 0;
		height: 1px;
		background-color: $color_yellow02;
	}
	&:after {
		top: auto;
		bottom: 5px;
	}
}
.login-ttl-box {
	position: absolute;
	top: 14px;
	left: 50%;
	margin: 0 0 0 -312px;
}
.login-ttl-btn {
	position: absolute;
	left: 0;
	bottom: 3px;
	z-index: 9;
}
.login-lead {
	padding: 38px 40px 13px;
	font-size: 16px;
	font-size: 1.6rem;
	letter-spacing: 0.65px;
}
.login-form-area {
	border: 1px solid $color_gray04;
	padding: 25px 50px 18px;
}
.login-form-inner {
	max-width: 350px;
	margin: 0 auto;
}
.login-form-lead {
	font-size: 18px;
	font-size: 1.8rem;
	+ .login-form-box {
		margin-top: 26px;
	}
}
.login-form-box {
	max-width: 300px;
	margin: 0 auto;
}
.login-form-ttl {
	font-size: 14px;
	font-size: 1.4rem;
	+ .login-form-input {
		margin-top: 1px;
	}
}
.login-form-input {
	+ .login-form-ttl {
		margin-top: 15px;
	}
}
.form-text {
	height: 34px;
	margin: 0;
	padding: 0 10px;
	box-sizing: border-box;
	border-radius: 4px;
	border: 1px solid $color_gray01;
	background-color: #fff;
	-webkit-appearance: none;
}
.form-login-btn-box {
	margin: 40px 0 0;
  button img{
    width:300px;
    height:50px;
  }
}
.form-login-btn {
	overflow: hidden;
	position: relative;
	padding: 12px 15px 12px;
	background: none;
	border-radius: 4px;
	box-sizing: border-box;
	border: 1px solid $color_yellow03;
	transition: all .5s;
	color: $color_yellow03;
	font-size: 16px;
	font-size: 1.6rem;
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		transition: all .5s;
		background: rgb(247,242,225);
		background: -moz-linear-gradient(top, rgba(247,242,225,1) 0%, rgba(247,245,237,1) 50%, rgba(241,235,211,1) 100%);
		background: -webkit-linear-gradient(top, rgba(247,242,225,1) 0%,rgba(247,245,237,1) 50%,rgba(241,235,211,1) 100%);
		background: linear-gradient(to bottom, rgba(247,242,225,1) 0%,rgba(247,245,237,1) 50%,rgba(241,235,211,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f2e1', endColorstr='#f1ebd3',GradientType=0 );
	}
}
.form-login-btn-text {
	position: relative;
	display: block;
	z-index: 9;
}
.login-form-attention {
	margin: 40px 0 0;
	padding: 17px 0 0;
	border-top: 1px solid $color_gray04;
	line-height: 1.7;
	font-size: 14px;
	font-size: 1.4rem;
}


@media only screen and (max-width: $sp_max_width) {
	.login-area {
		background: none;
	}
	.login-area-head {
		position: relative;
		height: auto;
		padding: 0;
		width: 700px/750px*100%;
		max-width: 700px;
		margin: 0 auto;
		background: none;
		&:before,
		&:after {
			display: none;
		}
	}
	.login-ttl-box {
		position: static;
		margin: 0;
	}
	.login-ttl {
		margin: 0 auto;
	}
	.login-ttl-btn {
		position: absolute;
		top: 67px/105px*100%;
		left: 13px/350px*100%;
		bottom: auto;
		z-index: 1;
		width: 131px/350px*100%;
	}
	.login-lead {
		padding: 11px 25px/750px*100% 9px;
		font-size: 15px;
		font-size: 1.5rem;
		line-height: 1.6;
		color: $color_black;
		letter-spacing: 0;
	}
	.login-form-inner {
		margin: 0 auto;
	}
	.login-form-lead {
		font-size: 17px;
		font-size: 1.7rem;
		+ .login-form-box {
			margin-top: 30px;
		}
	}
	.form-text {
		font-size: 16px;
		font-size: 1.6rem;
	}
	.login-form-attention {
		margin: 30px 0 0;
		padding: 18px 0 0;
		line-height: 1.6;
	}

}
@media only screen and (max-width: $sp_max_width_login) {
	.login-area {
		padding: 0;
	}
	.login-form-area {
		border-radius: 0;
		border: none;
		padding: 17px 55px/750px*100% 22px;
	}
}
