/* edge
--------------------------------------------------*/
@supports (-ms-ime-align:auto) {
	
}

@media only screen and (min-width: $pc_min_width) and (-ms-high-contrast:none) {
	*::-ms-backdrop, .top-news-ttl {
		padding-top: 5px;
	}
	*::-ms-backdrop, .top-news-ttl-text:after {
		margin-top: -3px;
	}
	*::-ms-backdrop, .top-main-link-ttl {
		padding-top: 3px;
	}
	*::-ms-backdrop, .top-main-link-ttl-icon {
		top: 1px;
	}
	*::-ms-backdrop, .top-tab-btn-text {
		padding-top: 6px;
	}
	*::-ms-backdrop, .info-main-ttl {
		padding-top: 5px;
		margin-bottom: -3px;
	}
}