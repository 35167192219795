/* top
----------------------------------------------------------*/
.top-label {
  position: relative;
  margin: 0 0 26px;
  img{
    width:100%;
  }
}
.top-label-btn {
  position: absolute;
  top: 82px;
  left: 34px;
  z-index: 9;
  >.pc-mode{
    background-image:url(../img/btn-price-top-on.png);
    @media only screen and (min-device-pixel-ratio: 2){
      background-image:url(../img/btn-price-top-on@2x.png);
    }
  }
}
.top-main-area {
  margin: 0 0 60px;
}
.top-main-head {
  margin: 0 auto 20px;
}
.top-main-ttl {
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 1.5;
}
.top-head-link {
  margin: 10px 0 0;
}
.top-head-link-icon {
  display: inline-block;
  margin: 0 -4px 0 4px;
  font-size: 9px;
  font-size: 0.9rem;
  transform: scale(0.5555);
}
.top-main-box {
  padding: 20px 20px 40px;
  border-top: 1px solid $color_yellow02;
  visibility: hidden;
}

.top-main-link-box {
  margin: 0 0 20px;
  padding: 15px 18px 15px;
}
.top-main-link-ttl-cell {
  width: 252px;
}
.top-main-link-ttl-icon {
  display: inline-block;
  position: relative;
  top: 4px;
  margin: 0 5px 0 0;
  font-size: 23px;
  font-size: 2.3rem;
  &:before {
    vertical-align: top;
  }
}
.top-main-link-ttl-img {
  padding-top: 4px;
}
.top-main-link-list {
  margin: 0 -10px -10px 0;
  font-size: 0;
}
.top-main-link-list-elm {
  display: inline-block;
  width: 200px;
  margin: 0 10px 10px 0;
  vertical-align: top;
}
.red-btn {
  position: relative;
  overflow: hidden;
  padding: 7px 53px 5px 30px;
  border-radius: 4px;
  border: 1px solid $color_red;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.4;
  color: $color_red;
  text-decoration: none;
  &:before {
    opacity: 0;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all .5s;
    background: rgb(167,42,46);
    background: -moz-linear-gradient(top, rgba(167,42,46,1) 0%, rgba(136,21,24,1) 50%, rgba(128,16,19,1) 100%);
    background: -webkit-linear-gradient(top, rgba(167,42,46,1) 0%,rgba(136,21,24,1) 50%,rgba(128,16,19,1) 100%);
    background: linear-gradient(to bottom, rgba(167,42,46,1) 0%,rgba(136,21,24,1) 50%,rgba(128,16,19,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a72a2e', endColorstr='#801013',GradientType=0 );
  }
  &:hover {
    color: #fff;
    &:before {
      opacity: 1;
    }
  }
  .icon-arrowr {
    position: absolute;
    top: 50%;
    right: 10px;
    z-index: 9;
    transform: translateY(-50%);
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1;
    &:before {
      vertical-align: top;
    }
  }
}

.red-btn02 {
  position: relative;
  overflow: hidden;
  padding: 7px 32px 5px 7px;
  border-radius: 2px;
  border: 1px solid $color_red;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.4;
  color: #fff;
  text-decoration: none;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all .5s;
    background: rgb(167,42,46);
    background: -moz-linear-gradient(top, rgba(167,42,46,1) 0%, rgba(136,21,24,1) 50%, rgba(128,16,19,1) 100%);
    background: -webkit-linear-gradient(top, rgba(167,42,46,1) 0%,rgba(136,21,24,1) 50%,rgba(128,16,19,1) 100%);
    background: linear-gradient(to bottom, rgba(167,42,46,1) 0%,rgba(136,21,24,1) 50%,rgba(128,16,19,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a72a2e', endColorstr='#801013',GradientType=0 );
  }
  &:hover {
    color: $color_red;
    &:before {
      opacity: 0;
    }
  }
  .icon-arrowr {
    position: absolute;
    top: 50%;
    right: 10px;
    z-index: 9;
    transform: translateY(-50%);
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1;
    &:before {
      vertical-align: top;
    }
  }
}

.top-tab-btn-box {
  overflow: hidden;
}
.top-tab-btn-list {
  display: inline-table;
  vertical-align: top;
  .top-tab-btn {
    display: table-cell;
    vertical-align: top;
  }
}
.top-tab-btn {
  width: 134px;
  color: $color_yellow;
  transition: all 0.5s;
  .table-cell {
    height: 30px;
  }
  &.current {
    color: #fff;
    .top-tab-btn-bg-elm:before,
    .top-tab-btn-bg-elm:after,
    .top-tab-btn-text:before {
      opacity: 1;
    }
    .table-cell {
      &:before {
        opacity: 0;
      }
      &:after {
        right: 10px;
        left: -4px;
        background-color: #fff;
      }
    }
  }
}
.top-tab-btn-inner {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 1px;
    right: 2px;
    z-index: 1;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 29px 0 0 23px;
    border-color: transparent transparent transparent #fff;

  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: -5px;
    right: 25px;
    bottom: 3px;
    background-color: #fff;
    border-radius: 2px 2px 0 0;
  }
  .table-cell {
    &:before {
      content: "";
      position: absolute;
      bottom: 3px;
      left: -5px;
      z-index: 1;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 0 0 6px;
      border-color: transparent transparent transparent $color_gray03;
      transition: opacity 0.5s;
    }
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 3px;
      z-index: 4;
      background-color: $color_gray03;
      transition: opacity 0.5s;
    }
  }
}
.top-tab-btn-bg-elm {
  &:before {
    opacity: 0;
    content: "";
    position: absolute;
    right: 5px;
    bottom: 0;
    z-index: 10;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 28px 0 0 20px;
    border-color: transparent transparent transparent $color_yellow;
    transition: opacity 0.5s;
  }
  &:after {
    opacity: 0;
    content: "";
    position: absolute;
    top: 2px;
    left: -2px;
    right: 25px;
    bottom: 0;
    z-index: 10;
    background-color: $color_yellow;
    transition: opacity 0.5s;
  }
}
.top-tab-btn-text {
  position: relative;
  z-index: 11;
  padding: 0 20px 0 0;
  font-size: 12px;
  font-size: 1.2rem;
  letter-spacing: 0.5px;
  &:before {
    opacity: 0;
    content: "";
    position: absolute;
    top: 50%;
    left: -8px;
    height: 50px;
    width: 4px;
    z-index: 4;
    background-color: $color_gray03;
    transition: all 0.5;
    transform: translateY(-50%);
    transition: opacity 0.5s;
  }
}

.top-tab-contents {
  display: none;
  padding: 8px 20px 20px;
  &.current {
    display: block;
  }
}
.top-tab-note{
  font-size:10px;
  text-align:center;
  line-height:1.4;
  padding:0 0 5px;
  @media only screen and (min-width: $pc_min_width) {
    font-size:12px;
    text-align:right;
  }
}
.top-tab-link {
  padding: 9px 19px 9px 9px;
  border-radius: 2px;
  border: 1px solid $color_gray05;
  + .top-tab-link {
    margin-top: 12px;
  }
}
.top-tab-link-inner {
  position: relative;
  .table-cell {
    height: 62px;
  }
}
.top-tab-link-img {
  width: 94px;
}
.top-tab-link-main {
  padding: 0 18px;
}
.top-tab-link-ttl-box {
  margin: 2px 0 4px;
}
.top-tab-link-ttl {
  display: inline-block;
  margin: 0 10px 0 0;
  font-size: 15px;
  font-size: 1.5rem;
  vertical-align: top;
}
.top-tab-link-ttl-link {
  display: inline-block;
  vertical-align: top;
  font-size: 12px;
  font-size: 1.2rem;
  .icon-blank {
    position: relative;
    top: 3px;
    margin: 0 0 0 7px;
  }
}
.top-tab-link-text-box {
  font-size: 0;
}
.top-tab-link-text {
  display: inline-block;
  font-size: 14px;
  font-size: 1.4rem;
  vertical-align: top;
}
.traffic-toggle-btn {
  position: relative;
  padding-right: 20px;
  margin: 0 21px 0 0;
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 6px 0 6px;
    border-color: $color_black02 transparent transparent transparent;
    margin: -1px 0 0;
  }
  &.on {
    &:after {
      border-width: 0 6px 6px 6px;
      border-color: transparent transparent $color_black02 transparent;
    }
  }
}
.traffic-toggle-btn-text {
  text-decoration: underline;
}
.top-tab-link-text-small {
  font-size: 12px;
  font-size: 1.2rem;
}
.top-tab-link-map {
  display: inline-block;
  margin: 0 0 0 8px;
  vertical-align: top;
  .icon-pin {
    position: relative;
    top: 2px;
    margin: 0 0 0 5px;
  }
}


.top-tab-link-btn-box {
  width: 221px;
  padding: 0 0 0 20px;
  border-left: 1px solid $color_gray05;
}
.top-tab-link-btn-lead {
  margin: -5px 0 1px;
  font-size: 12px;
  font-size: 1.2rem;
}

.top-tab-link-btn >a{
  position:relative;
  max-width: 196px;
  margin: 0 auto;
}

.traffic-toggle-area {
  display: none;
  &.current {
    display: block;
  }
}
.traffic-toggle-inner {
  margin: 8px 0 -4px;
  padding-top: 7px;
  border-top: 1px dotted $color_gray05;
  line-height: 1.7;
}
.traffic-toggle-text {
  font-size: 12px;
  font-size: 1.2rem;
}

.top-tab-link-more-btn {
  margin: 20px auto 0;
  max-width: 300px;
}
.yellow-btn {
  padding: 11px 14px 11px;
  border: 1px solid $color_yellow02;
  color: $color_yellow02;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.4;
  text-decoration: none;
  &:hover {
    color: #fff;
    background-color: $color_yellow02;
  }
}

.top-toggle-btn {
  user-select: none;
  position: relative;
  border: 1px solid $color_yellow02;
  box-sizing: border-box;
  width: 320px/350px*100%;
  margin: -1px auto 0;
  line-height: 1.2;
  &:before {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
    background-color: #F3EDD9;
    transition: opacity 0.5s;
  }
  &:after {
    opacity: 0;
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
    z-index: 1;
    background: rgb(240,222,157);
    background: -moz-linear-gradient(top, rgba(240,222,157,1) 0%, rgba(226,206,134,1) 50%, rgba(201,176,85,1) 100%);
    background: -webkit-linear-gradient(top, rgba(240,222,157,1) 0%,rgba(226,206,134,1) 50%,rgba(201,176,85,1) 100%);
    background: linear-gradient(to bottom, rgba(240,222,157,1) 0%,rgba(226,206,134,1) 50%,rgba(201,176,85,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f0de9d', endColorstr='#c9b055',GradientType=0 );
    transition: opacity 0.5s;
  }
  &.current {
    &:before {
      opacity: 0;
    }
    &:after {
      opacity: 1;
    }
    .top-toggle-btn-text-sub {
      color: #fff;
    }
    .top-toggle-btn-off-text {
      display: none;
    }
    .top-toggle-btn-on-text {
      display: block;
    }
  }
}
.top-toggle-btn-text {
  position: relative;
  z-index: 9;
  padding: 12px 130px 13px 24px/318px*100%;
  font-size: 19px;
  font-size: 1.9rem;
  letter-spacing: 0.8px;
  cursor: pointer;
}
.top-toggle-btn-text-sub {
  position: absolute;
  top: 50%;
  right: 11px;
  z-index: 9;
  transform: translateY(-50%);
  color: $color_yellow03;
  font-size: 12px;
  font-size: 1.2rem;
  transition: all 0.5s;
}
.top-toggle-btn-on-text {
  display: none;
}
.top-toggle-btn-icon {
  display: inline-block;
  margin: 0 -1px 0 2px;
  font-size: 9px;
  font-size: 0.9rem;
  transform: scale(0.66666);
}

.top-slider-area {
  margin: 0 0 41px;
}
.top-slider-head {
  margin: 0 0 34px;
  padding: 26px 0 0;
  border-top: 1px solid $color_gray01;
}
.top-slider-box {
  position: relative;
}

.top-slider-box{
  .slick-arrow {
    position: absolute;
    top: 50%;
    left: -27.5px;
    z-index: 9;
    transform: translateY(-50%);
    height: 155px;
    width: 20px;
    margin-top: -23px;
    background-size:20px 70px;
    overflow: hidden;
    white-space: nowrap;
    text-indent: 110%;
    transition: opacity .1s;
    opacity:1;
    outline:0;
    border: solid 1px #C9B055;
    &.slick-disabled{
      opacity:.3;
      cursor: default;
      &:hover{
        background-color:#FFF;
        &:before{
          // opacity:0.5;
          color:#C9B055;
        }
      }
    }
    &:before{
      position:absolute;
      top:50%;
      left:50%;
      text-indent: 0;
      transform:translate(-50%,-50%);
      color:#C9B055;
      font-size:12px;
      // content:"";
      // display: block;
      // width:100%;
      // height:100%;
      // transition: opacity .5s;
      // background-repeat:no-repeat;
      // background-position:50% 50%;
    }
    &:hover{
      // opacity:0.5;
      background-color:#C9B055;
      &:before{
        // opacity:0.5;
        color:#FFF;
      }
    }
  }
  .slick-next {
    left: auto;
    right: -27.5px;
    // background-image:url(../img/next-on.png);
    // &:before{
    //   background-image:url(../img/next.svg);
    //   background-size:7px 13px;
    // }
    // &:hover:before{
    //   background-image:url(../img/next-on.svg);
    // }
    @include icons(next);
  }
  .slick-prev {
    // background-image:url(../img/prev-on.png);
    // &:before{
    //   background-image:url(../img/prev.svg);
    //   background-size:7px 13px;
    // }
    // &:hover:before{
    //   background-image:url(../img/prev-on.svg);
    // }
    @include icons(prev);
  }
  .top-slider-inner {
    overflow: hidden;
  }
}

.top-slider {
  font-size: 0;
  width: 920px/890px*100%;
  margin: 0 0 0 -15px/890px*100%;
}
.slider-elm {
  display: inline-block;
  // width: 100%;
  // width:280px;
  margin: 0 0 20px;
  padding:0 12.5px 0;
  font-size: 12px;
  font-size: 1.2rem;
  text-align: left;
  line-height: 1.5;
  vertical-align: top;
  box-sizing: border-box;
}

#resort-slider .slider-elm > .slider-permalink{
  min-height:410px;
  @media only screen and (max-width: $sp_max_width) {
    min-height:auto;
  }
}

.slider-img {
  margin: 0 0 17px;
}
.slider-ttl {
  margin: 0 0 7px;
  font-size: 14px;
  font-size: 1.4rem;
}
.slider-lead01 {
  margin: 0 0 11px;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.6;
}

.slider-lead02 {
  margin: 0 0 3px;
  font-size: 12px;
  font-size: 1.2rem;
}
.slider-btn {
  margin: 0 0 7px;
  position:relative;
  .hv-op0{
    position:absolute;
    top:0;
    left:0;
    z-index:1;
  }
}

.gray-btn,
.gray-btn02 {
  padding: 6px 15px 6px;
  border: 1px solid $color_gray05;
  color: $color_gray01;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.4;
  text-decoration: none;
  &:hover {
    color: #fff;
    background-color: $color_gray05;
  }
}
.gray-btn02 {
  background-color: #fff;
  border: 1px solid $color_gray01;
  &:hover {
    background-color: $color_gray01;
  }
}
.btn-icon-blank {
  position: relative;
  top: 2px;
  margin: 0 0 0 3px;
}

.top-resort-header{
  @media only screen and (min-width: $pc_min_width) {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
}

.top-resort-header-note{
  font-size:10px;
  text-align:center;
  padding:5px 0 0;
  @media only screen and (min-width: $pc_min_width) {
    padding:0;
    font-size:12px;
    text-align:right;
  }
}

.top-news-area {
  padding: 40px 0 19px;
}
.top-news-ttl {
  overflow: hidden;
  margin: 0 0 30px;
}
.top-news-ttl-text {
  position: relative;
  display: inline-block;
  vertical-align: top;
  font-size: 20px;
  font-size: 2rem;
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    height: 1px;
    width: 100vw;
    background-color: $color_gray01;
    margin: 1px 0 0 10px;
  }
}

.btn-reservation{
  background-color: #A72A2E;
  background-image: linear-gradient(-180deg, #A72A2E 3%, #881518 48%, #801013 95%);
  border: 1px solid #700508;
  border-radius: 2px;
  display: block;
  line-height:30px;
  justify-content: center;
  align-items: center;
  height: 30px;
  box-sizing: border-box;
  padding: 0 15px 0 0;
  position:relative;
  &:before{
    content:"";
    display:block;
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    background-image: linear-gradient(-180deg, #D27E81 3%, #A73437 48%, #9E2D31 95%);
    opacity:0;
    transition: opacity .5s;
  }
  img{
    display:inline-block!important;
  }
  .text-reservation{
    width:103px;
    height:15px;
    margin-top: -2px;
    position:relative;
    vertical-align:middle;
  }
  .arrow{
    width:16px;
    height:16px;
    position: absolute;
    right: 11px;
    top: 50%;
    // transform: translate(0,-50%);
    margin-top:-8px;
  }
  &:hover{
    border: 1px solid #C95555;
    &:before{
      opacity:1;
    }
  }
}
.slider-cacth {
  margin: 0 0 7px;
}
@media only screen and (max-width: $sp_max_width) {
  .top-label {
    width: 700px/750px*100%;
    max-width: 700px;
    margin: 0 auto 11px;
  }
  .top-label-btn {
    top: 68px/105px*100%;
    left: 13px/350px*100%;
    z-index: 1;
    width: 131px/350px*100%;
  }
  .top-main-area {
    margin: 0 0 25px;
  }
  .top-main-head {
    margin: 0 0 10px;
  }
  .top-main-ttl {
    font-size: 19px;
    font-size: 1.9rem;
    text-align: center;
  }
  _::-webkit-full-page-media, _:future, :root .top-main-ttl {
    font-size: 18px;
    font-size: 1.8rem;
  }

  .top-main-box {
    padding: 12px 25px/750px*100% 12px;
    border-bottom: 1px solid $color_yellow02;
  }

  .top-main-link-box {
    margin: 0 0 13px;
    padding: 8px 17px/350px*100% 11px;
  }
  .top-main-link-ttl-cell {
    width: auto;
    margin: 0 0 5px;
  }
  .top-main-link-ttl {
    font-family: inherit;
    font-weight: normal;
  }
  .top-main-link-ttl-icon {
    top: 1px;
    margin: 0 4px 0 -3px;
  }

  .top-main-link-ttl-img {
    padding: 0;
    margin: 0px 0 0 -3px;
  }
  .top-main-link-list {
    width: 330px/316px*100%;
    margin: 0 0 0 -8px/316px*100% 0;
    font-size: 0;
  }
  .top-main-link-list-elm {
    width: 100px/330px*100%;
    margin: 0 8px/330px*100% 8px/330px*100% 0;
  }
  .red-btn-text {
    display: none;
  }
  .red-btn {
    padding: 7px 29px 5px 10px;
    .icon-arrowr {
      right: 6px;
    }
  }

  .red-btn02 {
    padding: 7px 29px 6px 8px;
    border-radius: 2px;
    font-size: 11px;
    font-size: 1.1rem;
  }


  .top-main-link-box02 {
    padding: 15px 0 14px;
  }
  .top-tab-contents {
    padding: 6px 15px/350px*100% 0;
    &:last-child {
      .top-tab-link-list {
        margin-bottom: 0;
      }
    }
  }
  .top-tab-link-list {
    margin: 0 0 15px;
  }
  .top-tab-link {
    padding: 9px 14px/350px*100% 9px;
    border-radius: 2px;
    + .top-tab-link {
      margin-top: 7px;
    }
  }
  .top-tab-link-inner {
    .table-cell {
      height: auto;
    }
  }
  .top-tab-link-main {
    padding: 0;
  }
  .top-tab-link-ttl-box {
    position: relative;
    margin: 0 0 4px;
    padding-right: 63px;
  }
  .top-tab-link-ttl {
    display: block;
    margin: 2px 0 0;
    font-size: 13px;
    font-size: 1.3rem;
  }
  .top-tab-link-ttl-link {
    position: absolute;
    top: 0;
    right: 0;
    .icon-blank {
      top: 3px;
      margin: 0 0 0 6px;
    }
  }
  .top-tab-link-text {
    display: block;
    font-size: 13px;
    font-size: 1.3rem;
    line-height: 1.8;
    + .top-tab-link-text:before {
      display: none;
    }
  }


  .traffic-toggle-btn {
    display: inline-block;
    padding-right: 23px;
    margin: 0;
    vertical-align: top;
    &:after {
      margin: -2px 0 0;
    }
  }

  .top-tab-link-text-small {
    font-size: 10px;
    font-size: 1rem;
  }
  .top-tab-link-map {
    margin: 0 0 0 6px;
    font-size: 13px;
    font-size: 1.3rem;
    .icon-pin {
      top: 0;
      margin: 0 0 0 7px;
    }
  }

  .top-tab-link-btn-box {
    display: table;
    table-layout: fixed;
    width: 100%;
    margin: 5px 0 0;
    padding: 10px 0 0;
    border-top: 1px solid $color_gray05;
    border-left: 0;
  }

  .top-tab-link-img {
    display: table-cell;
    width: 160px/580px*100%;
    vertical-align: top;
  }
  .top-tab-link-btn-lead-box {
    display: table-cell;
    padding-left: 16px/292px*100%;
    vertical-align: middle;
  }
  .top-tab-link-btn-lead {
    margin: -6px 0 1px;
  }

  .traffic-toggle-inner {
    margin: 4px 0 17px;
    padding-top: 7px;
    line-height: 1.4;
  }
  .traffic-toggle-text {
    font-size: 13px;
    font-size: 1.3rem;
  }

  .top-tab-link-more-btn {
    margin: 20px auto 0;
    max-width: 300px;
  }
  .yellow-btn {
    padding: 11px 15px 11px;
  }

  .top-slider-area {
    margin: 0 0 22px;
  }
  .top-slider-head {
    margin: 0 0 26px;
    padding: 0 25px/750px*100% 0;
    border-top: none;
  }
  .top-slider-box {
    position: relative;
  }
  .top-slider {
    width: auto;
    margin: 0;
    padding: 0 95px/750px*100%;
    &.type-news {
      .slider-elm + .slider-elm {
        margin-top: 25px;
        padding-top: 0;
        &:before {
          display: none;
        }
      }
    }
  }
  .slider-elm {
    position: relative;
    display: block;
    width: auto;
    margin: 0;
    + .slider-elm {
      margin-top: 22px;
      padding-top: 20px;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: -40px/560px*100%;
        height: 1px;
        width: 640px/560px*100%;
        background-color: $color_gray05;
      }
    }
  }
  .slider-img {
    margin: 0 0 16px;
  }
  // .slider-cacth {
  //   margin: 0 0 1px;
  // }
  .slider-ttl {
    margin: 0 0 7px;
  }
  .slider-lead01 {
    margin: 0 0 10px;
  }
  .slider-lead02 {
    margin: 0 0 2px;
  }
  .slider-btn {
    .hv-op0{
      position:static;
    }
  }
  .gray-btn {
    padding: 6px 15px 6px;
    border: 1px solid $color_gray01;
    color: $color_gray01;
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 1.4;
    text-decoration: none;
    &:hover {
      color: #fff;
      background-color: $color_gray01;
    }
  }
  .gray-btn02 {
    padding: 12px 15px 10px;
  }

  .top-news-area {
    padding: 29px 0 20px;
  }
  .top-news-ttl {
    margin: 0 0 36px;
    padding: 0 55px/750px*100%;
    text-align: center;
  }
  .top-news-ttl-text {
    position: relative;
    display: block;
    padding: 0 0 15px;
    border-bottom: 1px solid $color_gray01;
    &:after {
      display: none;
    }
  }

  .top-news-more-btn-box {
    margin-top: 13px;
  }
  .top-news-more-btn {
    border-top: 1px solid $color_gray01;
    padding: 20px 40px/640px*100% 0;
  }
}
@media only screen and (max-width: $sp_max_width_login) {

}
