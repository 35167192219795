/* map
----------------------------------------------------------*/
.map-area {
	padding: 0 0 60px;
}
.map-ttl-box {
	padding: 24px 0 23px;
	margin: 0 0 60px;
}
.map-ttl {
	font-size: 18px;
	font-size: 1.8rem;
}
.map-text {
	margin: 15px auto 0;
}
.map-btn {
	max-width: 300px;
	margin: 23px auto 0;
}
.map-btn-icon {
	margin: 0 9px 0 -3px;
	font-size: 10px;
	font-size: 1rem;
}
@media only screen and (max-width: $sp_max_width_map) {
	.map-area {
		margin: 0;
		padding: 0 0 40px;
	}
	.map-iframe {
		padding-top: 440px;
	}
	.map-ttl-box {
		padding: 10px 0 6px;
		margin: 0 0 10px;
		line-height: 1.25;
	}
	.map-text {
		margin: 5px auto 0;
		padding: 0 15px/350px*100%;
	}
	.map-btn {
		max-width: 280px;
		margin: 8px auto 0;
	}
}
