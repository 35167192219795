/* bestprice
----------------------------------------------------------*/

$bestprice-bg-color:#F4F2ED;
.bestprice-area{
	width: 740px;
	margin:0 auto;
	background-color: $bestprice-bg-color;
	overflow: auto;
}
.bestprice-contents{
	margin:0 50px 50px;
	background-color: #FFFFFF;
	padding:35px 40px 34px;
	section{
		&.not_covered{
			margin-top: 27px;
		}
		h3{
			font-family: $base_font_family;
			font-size: 14px;
			color: #640205;
			letter-spacing: 0;
			font-weight: bold;
			margin-bottom: 6px;
		}
		ul,p{
			font-family: $base_font_family;
			font-size: 14px;
			color: #4A4A4A;
			letter-spacing: 0;
			line-height: 26px;
		}
		ul.small-list{
			font-family: $base_font_family;
			font-size: 12px;
			line-height: 22px;
			margin-top: 18px;
			padding: 0 21px;
		}
	}
}
.bestprice-close-btn{
	display: block;
	width: 300px;
	margin:40px auto 60px;
}
@media only screen and (max-width: $sp_max_width_bestprice) {
	#bestprice{
		.global-header{
			// display: none;
		}
		.contents{
			overflow: auto;
			background-color: $bestprice-bg-color;
		}
	}
	.bestprice-area{
		width: 100%;
	}
	.bestprice-contents{
		margin:0 25/750*100% 0;
		padding:48/700*100% 30/700*100% 50/700*100%;
		section{
			&.not_covered{
				margin-top: 18px;
			}
			h3{
				margin-bottom: 6px;
			}
			ul,p{
			}
			ul.small-list{
				margin-top: 6px;
				padding: 0;
			}
		}
	}
	.bestprice-close-btn{
		display: block;
		width: 600/750*100%;
		margin:30/750*100% auto;
		.yellow-btn{
			background-color: #FFFFFF;
		}
	}
}
