/*
#overview
共通iconfont
iconfont一覧
*/

@font-face {
	font-family: "iconfont";
	src: url('../css/iconfont.eot');
	src: url('../css/iconfont.eot?#iefix') format('eot'),
		url('../css/iconfont.woff2') format('woff2'),
		url('../css/iconfont.woff') format('woff'),
		url('../css/iconfont.ttf') format('truetype'),
		url('../css/iconfont.svg#iconfont') format('svg');
}

@mixin icons-styles {
	font-family: "iconfont";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	// speak: none; // only necessary if not using the private unicode range (firstGlyph option)
	text-decoration: none;
	text-transform: none;
}

%icons {
	@include icons-styles;
}

@function icons-char($filename) {
	$char: "";

	@if $filename == next {
		$char: "\E001";
	}
	@if $filename == prev {
		$char: "\E002";
	}

	@return $char;
}

@mixin icons($filename, $insert: before, $extend: true) {
	&:#{$insert} {
		@if $extend {
			@extend %icons;
		} @else {
			@include icons-styles;
		}
		content: icons-char($filename);
	}
}

.icons-next {
	/*
	#styleguide
	```
	<i class="icons-next"></i>
	```
	*/
	@include icons(next);
}
.icons-prev {
	/*
	#styleguide
	```
	<i class="icons-prev"></i>
	```
	*/
	@include icons(prev);
}
