@font-face {
  font-family: 'icon';
  src:  url('../fonts/icomoon.eot?ql1yz7');
  src:  url('../fonts/icomoon.eot?ql1yz7#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?ql1yz7') format('truetype'),
    url('../fonts/icomoon.woff?ql1yz7') format('woff'),
    url('../fonts/icomoon.svg?ql1yz7#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  line-height: 1;
}
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: 'icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
}

.icon-close:before {
  content: "\e90c";
}
.icon-toggle-off:before {
  content: "\e90a";
}
.icon-toggle-on:before {
  content: "\e90b";
}
.icon-arrowb:before {
  content: "\e900";
}
.icon-back:before {
  content: "\e902";
}
.icon-blank:before {
  content: "\e903";
}
.icon-next:before {
  content: "\e904";
}
.icon-pagetop:before {
  content: "\e905";
}
.icon-pin:before {
  content: "\e906";
}
.icon-prev:before {
  content: "\e907";
}
.icon-tri:before {
  content: "\e908";
}
