/* info
----------------------------------------------------------*/
.info-head {
	padding: 28px 0 9px;
}
.info-main-ttl {
	font-size: 22px;
	font-size: 2.2rem;
	line-height: 1;
}
.topic-pass {
	font-size: 0;
	li {
		display: inline-block;
		vertical-align: top;
		font-size: 12px;
		font-size: 1.2rem;
		+ li {
			&:before {
				content: ">";
				padding: 0 8px;
			}
		}
	}
}
.info-post-box {
	padding-top: 53px;
	padding-bottom: 98px;
}
.info-post-date {
	font-size: 12px;
	font-size: 1.2rem;
}
.info-post-ttl {
	margin: 0 0 31px;
	font-size: 18px;
	font-size: 1.8rem;
}
.info-post-img {
	margin: 0 auto 56px;
}
.info-post-text {
	margin: 0 0 33px;
	line-height: 1.9;
	p + p {
		margin-top: 26px;
	}
	a {
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
}
.info-label-box {
	position: relative;
	+ .info-link-box {
		border-top: 0;
	}
}
.info-label-btn {
	position: absolute;
	top: 108px;
	left: 18px;
	z-index: 9;	
}
.info-link-box {
	padding: 14px 20px 17px;
	border: 1px solid $color_gray05;
}
.info-post-link-list {
	li {
		position: relative;
		padding: 0 0 0 12px;
		+ li {
			margin-top: 1px;
		}
	}
	.icon-tri {
		position: absolute;
		top: 8px;
		left: 0;
		z-index: 1;
		font-size: 8px;
		font-size: 0.8rem;
	}
}
.info-pager-box {
	padding-top: 30px;
	padding-bottom: 80px;
	border-top: 1px solid $color_gray01;
}
.info-pager {
	width: 280px;
}
.info-pager-link {
	position: relative;
	padding: 11px 19px 8px 49px;
	border: 1px solid $color_gray03;
}
.info-pager-icon {
	position: absolute;
	top: 50%;
	left: 21px;
	transform: translateY(-50%);
	font-size: 9px;
	color: $color_gray04;
}
.info-pager-date {
	font-size: 12px;
	font-size: 1.2rem;
	line-height: 1.7;
}
.info-pager-ttl {
	font-size: 14px;
	font-size: 1.4rem;
}
.info-pager-prev {
	float: left;
}
.info-pager-next {
	float: right;
	.info-pager-link {
		padding: 11px 49px 8px 19px;
	}
	.info-pager-icon {
		left: auto;
		right: 21px;
	}
}
@media only screen and (max-width: $sp_max_width) {
	.info-head {
		padding: 0;
	}
	.info-ttl-box {
		padding-top: 5px;
		padding-bottom: 5px;
	}
	.info-main-ttl {
		font-size: 20px;
		font-size: 2rem;
		line-height: 1.5;
	}
	.topic-pass-box {
		padding-top: 6px;
		background-color: #fff;
	}
	.info-post-box {
		padding-top: 21px;
		padding-bottom: 39px;
	}
	.info-post-img {
		margin: 0 auto 30px;
	}
	.info-post-date {
		line-height: 1.5;
	}
	.info-post-ttl {
		margin: 0 0 21px;
	}
	.info-label-btn {
		top: 115px/155px*100%;
		left: 13px/320px*100%;
		width: 187px/320px*100%;
	}
	.info-link-box {
		padding: 14px 19px/320px*100% 16px;
	}
	.info-pager-box {
		padding-top: 25px;
		padding-bottom: 40px;
	}
	.info-pager {
		margin: 0 auto;
	}
}
@media only screen and (max-width: $sp_max_width_info) {
	.info-pager-prev {
		float: none;
		+ .info-pager-next {
			margin-top: 10px;
		}
	}
	.info-pager-next {
		float: none;
	}
}

