/* base
----------------------------------------------------------*/
html {
	height: 100%;
	font-size: 62.5%;
}
body {
	height: 100%;
	font-family: $base_font_family;
	font-size: 14px;
	font-size: 1.4rem;
	line-height: 1.85;
	color: $color_black;
	-webkit-text-size-adjust: 100%;
	text-size-adjust: 100%;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-variant-ligatures: none;
}
img {
	max-width: 100%;
	height: auto;
	vertical-align: top;
	-ms-interpolation-mode: bicubic;
}


/* header
----------------------------------------------------------*/
.global-header {
	min-height: 90px;
}
.global-header-inner {
	position: relative;
	max-width: 910px;
	margin: 0 auto;
	padding: 0 40px;
}
.site-ttl {
  width:434px;
  margin: 0 auto;
  padding: 25px 0 0;
  img{
    width:100%;
    height:auto;
  }
}
.global-header-logo {
	padding-top: 8px;
}
.global-header-text-box {
	padding: 0;
  display:flex;
  justify-content: space-between;
  align-items: flex-end;
  width:100%;
  padding: 25px 0 0;
}
.global-header-user {
	margin: 0 0 9px;
	font-size: 18px;
	font-size: 1.8rem;
	line-height: 1.2;
  box-sizing:border-box;
  padding:0 1em 0 0;
}
.global-header-logout {
	margin: 0 0 9px;
	font-size: 12px;
	font-size: 1.2rem;
	letter-spacing: 0.4px;
	.icon-tri {
		margin: 0 7px 0 0;
		font-size: 10px;
		font-size: 1rem;
	}
}
@media only screen and (max-width: $sp_max_width) {
	.global-header {
		height: auto;
		min-height: 70px;
		padding: 0 12px/375px*100% 0;
	}
	.global-header-inner {
		padding: 0;
		&.cf:after {
			display: none;
		}
	}
  .global-header-text-box {
    height:auto;
    // flex-wrap: wrap;
    display:block;
    overflow:hidden;
    padding:0;
  }
	.global-header-right-box {
		float: none;
		width: auto;
		line-height: 1.2;
		padding: 14px 0 1px;
		vertical-align: top;
	}
	.global-header-logo {
		text-align: right;
		a {
			display: inline-block;
			max-width: 98px;
			vertical-align: top;
		}
	}
	.site-ttl {
		width: 100%;
    height:auto;
		margin: 0;
    padding:9px 0 0;
    floa:left;
    img{
      width:304px;
    }
	}
	.global-header-user {
		margin: 14px 0 5px;
    width:50%;
		font-size: 11px;
		font-size: 1.08rem;
		line-height: 1.2;
		vertical-align: top;
    float:left;
	}
	.global-header-logout {
    width:50%;
		margin: 13px 0 5px;
		font-size: 8px;
		font-size: 0.8rem;
		letter-spacing: 0.2px;
		vertical-align: top;
    float:left;
		.icon-tri {
			position: relative;
			top: 1px;
			margin: 0 4px 0 0;
			font-size: 7px;
			font-size: 0.7rem;
		}
	}
}


/* footer
----------------------------------------------------------*/
.home-link-box {
	padding: 8px 0 10px;
}
.home-link-text {
	font-size: 12px;
	font-size: 1.2rem;
	.icon-back {
		position: relative;
		top: 2px;
		margin: 0 6px 0 0;
		font-size: 14px;
		font-size: 1.4rem;
	}
}
.pagetop {
	position: absolute;
	right: 50px;
	top: -9px;
	z-index: 999;
	line-height: 1;
	font-size: 10px;
	font-size: 1rem;
	&.fix {
		position: fixed;
		top: auto;
		bottom: 40px;
	}
	.icon-pagetop {
		position: relative;
		height: 28px;
		width: 28px;
		margin: 0 0 6px;
		border: 1px solid $color_yellow02;
		background-color: #fff;
		transition: all .5s;
		&:before {
			position: absolute;
			top: 10px;
			left: 0;
			right: 0;
		}
	}
	a {
		text-decoration: none;
		&:hover {
			.icon-pagetop {
				background-color: $color_yellow02;
				color: #fff;
			}
		}
	}
}
.global-footer {
	position: relative;
	padding: 40px 0 40px;
}
.global-footer-logo02 {
	margin: 0 0 28px;
}
.global-footer-link {
	margin: 0 0 25px;
	font-size: 0;
	li {
		display: inline-block;
		vertical-align: top;
		font-size: 12px;
		font-size: 1.2rem;
		+li:before {
			content: "　｜　";
		}
	}
}
.global-footer-logo {
	margin-top: 23px;
}
@media only screen and (max-width: $sp_max_width) {
	.home-link-text {
		.icon-back {
			margin: 0 5px 0 0;
		}
	}
	.pagetop {
		right: 12px/375px*100%;
		top: -14px;
		&.fix {
			bottom: 55px;
		}
	}
	.global-footer {
		padding: 30px 0 25px;
		border-top: 1px solid $color_gray04;
	}
	.global-footer-logo02 {
		margin: 0 0 25px;
	}
	.global-footer-link {
		margin: 0 0 11px;
		border-top: 1px solid $color_gray05;
		li {
			display: block;
			border-bottom: 1px solid $color_gray05;
			font-size: 14px;
			font-size: 1.4rem;
			text-align: center;
			+li:before {
				display: none;
			}
		}
		a {
			display: block;
			padding: 8px 15px 7px;
		}
		.hv-line-none {
			text-decoration: none;
		}
	}
	.copyright {
		margin: 11px 0 0;
		font-size: 14px;
		font-size: 1.4rem;
	}
	.global-footer-logo {
		max-width: 320px;
		margin: 23px auto 0;
		padding: 0 10px;
	}
}
