/* contents
----------------------------------------------------------*/
.wrapper {
	overflow: hidden;
}
.ct-inner {
	max-width: 950px;
	margin: 0 auto;
}
.ct-inner-570 {
	max-width: 570px;
	margin: 0 auto;
}
.ct-inner-600 {
	max-width: 600px;
	margin: 0 auto;
}
.ct-inner-700 {
	max-width: 700px;
	margin: 0 auto;
}
.ct-inner-890 {
	max-width: 890px;
	margin: 0 auto;
}
.ct-inner-910 {
	max-width: 910px;
	margin: 0 auto;
}
.ct-inner-915 {
  max-width: 915px;
  margin: 0 auto;
}
.ct-side-space {
	padding-left: 40px;
	padding-right: 40px;
}
@media only screen and (max-width: $sp_max_width) {
	.ct-side-space {
		padding-left: 55px/750px*100%;
		padding-right: 55px/750px*100%;
	}
	.ct-side-space-25-sp {
		padding-left: 25px/750px*100%;
		padding-right: 25px/750px*100%;
	}
}


.color-white {
	color: #fff;
	a {
		color: #fff;
	}
}
.color-black {
	color: $color_black;
	a {
		color: $color_black;
	}
}
.color-black02 {
	color: $color_black02;
	a {
		color: $color_black02;
	}
}
.color-blue {
	color: $color_blue;
	a {
		color: $color_blue;
	}
}
.color-yellow {
	color: $color_yellow;
	a {
		color: $color_yellow;
	}
}
.color-yellow02 {
	color: $color_yellow02;
	a {
		color: $color_yellow02;
	}
}
.color-gray {
	color: $color_gray01;
	a {
		color: $color_gray01;
	}
}
.color-brown {
	color: $color_brown;
	a {
		color: $color_brown;
	}
}
.color-red {
	color: $color_red;
	a {
		color: $color_red;
	}
}
.color-red-lt {
	color: $color_red_lt;
	a {
		color: $color_red_lt;
	}
}
.bg-white {
	background-color: #fff;
}
.bg-yellow {
	background-color: $color_yellow;
}
.bg-gray01 {
	background-color: $color_gray01;
}
.bg-gray02 {
	background-color: $color_gray02;
}
.bg-gray03 {
	background-color: $color_gray03;
}
.bg-brown {
	background-color: $color_brown;
}
a {
	color: $color_black;
	transition: all .5s;
	&:hover {
		text-decoration: underline;
	}
}
.btn-elm {
	cursor: pointer;
}
.hv-op0,
.hv-op {
	transition: opacity .5s;
}
.transition {
	transition: all .5s;
}
.f-min {
	font-family: $font_yumin;
}
.fs-i {
	font-style: italic;
}
.fw-b {
	font-weight: bold;
}
.td-none {
	text-decoration: none;
}
.lazyload,
.lazyloading {
	opacity: 0;
	transition: opacity 0.8s;
}
.lazyloaded {
	opacity: 1;
	transition: opacity 0.8s;
}

/* layout */
.of-hid {
	overflow: hidden;
}
.bs-b {
	box-sizing: border-box;
}
.br-4 {
	border-radius: 4px;
}
.br-50 {
	border-radius: 50%;
}
.cf {
	&:after {
		content: "";
		clear: both;
		display: block;
	}
}
.fl-l {
	float: left;
}
.fl-r {
	float: right;
}
.txt-l {
	text-align: left;
}
.txt-c {
	text-align: center;
}
.txt-r {
	text-align: right;
}
.txt-j {
	text-align: justify;
}
.f-feature {
	-webkit-font-feature-settings : "palt";
	-moz-font-feature-settings : "palt";
	font-feature-setting : "palt";
	letter-spacing: 1.5px;
}
_::-webkit-full-page-media, _:future, :root .f-feature {
	-moz-font-feature-settings : "pkna";
	font-feature-setting : "pkna";
	letter-spacing: 0;
}
.posi-r {
	position: relative;
}
.table-style {
	display: table;
	table-layout: fixed;
	width: 100%;
	&.i-block {
		display: inline-table;
	}
	&.w-auto {
		width: auto;
	}
}
.table-cell {
	display: table-cell;
}
.i-block {
	display: inline-block;
}
.block {
	display: block;
}
.bg-style {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
}
.va-top {
	vertical-align: top;
}
.va-mid {
	vertical-align: middle;
}
.va-b {
	vertical-align: bottom;
}
.w100 {
	width: 100%;
}
.h100 {
	height: 100%;
}
img.w100 {
	height: auto;
}
.w-auto {
	width: auto;
}
.mg-c {
	margin-left: auto;
	margin-right: auto;
}
.pt100 {
	padding-top: 100%;
}
.hidden {
	display: none !important;
}
.wb-ba {
	word-break: break-all;
}
.lh-1 {
	line-height: 1;
}
.posi-full {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
}
.posi-center-elm {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 1;
	transform: translateX(-50%) translateY(-50%);
}
.over-elm {
	position: relative;
	z-index: 11;
}

.iframe-wrap {
	overflow: hidden;
	position: relative;
	padding-top: 460px/910px*100%;
	video,
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
	}
}
button,
a,
.btn-elm {
	&:hover {
		text-decoration: none;
		.hv-op {
			opacity: $opacity;
		}
		.hv-op0 {
			opacity: 0;
		}
		.hv-line {
			text-decoration: underline;
		}
	}
	&.hv-op {
		&:hover {
			opacity: $opacity;
		}
	}
}
.hv-line {
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
}
.hv-gray {
	&:hover {
		background-color: $color_gray06;
	}
}
@media only screen and (min-width: $pc_min_width) {
	.table-style-pc {
		display: table;
		table-layout: fixed;
		width: 100%;
		&.w-auto {
			width: auto;
		}
	}
	.table-style-i-pc {
		display: inline-table;
		table-layout: fixed;
		width: 100%;
		&.w-auto {
			width: auto;
		}
	}
	.table-cell-pc {
		display: table-cell;
	}
	.sp-mode {
		display: none !important;
	}
	.txt-c-pc {
		text-align: center;
	}
	.txt-r-pc {
		text-align: right;
	}
}
@media only screen and (max-width: $sp_max_width) {
	.pc-mode {
		display: none !important;
	}
	.sp-br-none br {
		display: none;
	}
	.fl-l,
	.fl-r {
		float: none;
	}
	.txt-c-sp {
		text-align: center;
	}
	.txt-r-sp {
		text-align: right;
	}
}
